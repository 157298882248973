import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class PartZoneApplicationsRepository extends BaseRepository {
    baseUrl = 'management/dynamic-fields/part-zone-applications';

    /**
     * Returns all records by sector
     *
     * @returns {*}
     */
    allBySector(criteria) {
        return this.httpClient.post(`${this.baseUrl}/all-by-sector`, criteria);
    }
}
